// i18next-extract-mark-ns-start products-wave-technical-papers

import { WAVE_APPLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductWavePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<h2>Published papers</h2>
						<ul>
							<li>
								<p>
									<a
										href="http://papers.sae.org/2014-01-2062/"
										target="_blank"
										rel="noreferrer">
										SAE 2014-01-2062
									</a>:
									Streamlining the Process of Developing Intake
									and Using an Improved Linear Simulation Approach
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://papers.sae.org/2011-01-1524/"
										target="_blank"
										rel="noreferrer">
										SAE 2011-01-1524
									</a>:
									Linear Acoustic Modelling using 1-D Flow Systems
									which represent Complex 3-D Components
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://papers.sae.org/2011-01-0849/"
										target="_blank"
										rel="noreferrer">
										SAE 2011-01-0849
									</a>:
									Implementing Detailed Chemistry and In-Cylinder
									Stratification into 0 / 1-D IC Engine Cycle
									Simulation Tools
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://papers.sae.org/2008-01-1006/"
										target="_blank"
										rel="noreferrer">
										SAE 2008-01-1006
									</a>:
									Real-Time Crank-Resolved Engine Simulation for
									Testing New Engine Management Systems
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://papers.sae.org/2007-01-0266/"
										target="_blank"
										rel="noreferrer">
										SAE 2007-01-0266
									</a>:
									Real-Time Crank-Resolved Engine Simulation for
									Testing New Engine Management Systems
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2006-01-0634"
										target="_blank"
										rel="noreferrer">
										SAE 2006-01-0634
									</a>:
									Modelling and Experimental investigations of
									Supercharged HCCI Engines
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-3801"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-3801
									</a>:
									A Hybrid 2-Zone/Wave Engine Combustion Model for
									Simulating Combustion Instabilities During
									Dilute Operation
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-3746"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-3746
									</a>:
									A Modeling Investigation into the Optimal Intake
									and Exhaust Valve Event Duration and Timing for
									a HCCI Engine
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-2352"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-2352
									</a>:
									Flow Excited Noise Analysis of Exhaust
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-2351"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-2351
									</a>:
									Passive Exhaust System With Cylinder
									Deactivation
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-2133"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-2133
									</a>:
									Characteristics of HCCI Engine Operating in the
									Negative-Valve-Overlap Mode
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2005-01-2123"
										target="_blank"
										rel="noreferrer">
										SAE 2005-01-2123
									</a>:
									Modelling of HCCI Engines: Comparison of
									Single-Zone, Multi-Zone and Test Data
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2003-01-1875"
										target="_blank"
										rel="noreferrer">
										SAE 2003-01-1875
									</a>:
									A Coupled 1D/3D Simulation for the Flow Behavior
									Inside a Close-Coupled Catalytic Converter
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2003-01-1655"
										target="_blank"
										rel="noreferrer">
										SAE 2003-01-1655
									</a>:
									Design of Experiment - Application of a
									Statistical Evaluation Method to Optimize the
									Tailpipe Noise of An Exhaust System
								</p>
							</li>
							<li>
								<p>
									<a
										href="http://www.sae.org/technical/papers/2003-01-0752"
										target="_blank"
										rel="noreferrer">
										SAE 2003-01-0752
									</a>:
									A Parametric Study of HCCI Combustion--The
									Sources of Emissions At Low Loads and the
									Effects of GDI Fuel Injection
								</p>
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{WAVE_APPLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductWavePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-wave-technical-papers", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
}
`;

